import { CheckedStateItem, TestData } from '../../../types';

export const initializeCheckedState = (
  testData: TestData,
  setCheckedState: React.Dispatch<React.SetStateAction<CheckedStateItem>>
) => {
  if (testData) {
    const initialCheckedState = testData.questions.map(question =>
      question.answers.map(answer => ({
        id: answer.id,
        value: false,
      }))
    );
    setCheckedState(initialCheckedState);
  }
};
