import { ChangeEvent } from 'react';
import { AnswerItem, CheckedStateItem } from '../../../types';

export const updateCheckboxState = (
  e: ChangeEvent<HTMLInputElement & { value: { id: number } }>,
  position: number,
  checkedState: CheckedStateItem,
  currentQuestionIndex: number,
  setCheckedState: React.Dispatch<React.SetStateAction<any>>,
  answer: AnswerItem[],
  setAnswer: React.Dispatch<React.SetStateAction<AnswerItem[]>>
) => {
  const updatedCheckedState = checkedState.map((item, index) =>
    index === currentQuestionIndex
      ? item.map((checkboxData, idx) =>
          idx === position
            ? { id: checkboxData.id, value: !checkboxData.value }
            : checkboxData
        )
      : item
  );

  setCheckedState(updatedCheckedState);

  const isChecked = e.target.checked;
  const checkboxValue = e.target.value;
  const isFound = answer.some(item => item.id === checkboxValue.id);
  if (isChecked) {
    if (!isFound) {
      setAnswer((prev: any) => [...prev, { id: checkboxValue.id }]);
    }
  } else {
    const updatedAnswer = answer.filter(
      (item: AnswerItem) => item.id !== checkboxValue.id
    );
    setAnswer(updatedAnswer);
  }
};
