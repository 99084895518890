import React, {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TIME_CONSTANTS } from '../constants/timeConstants';
import {
  AnswerItem,
  CheckboxState,
  CorrectAnswer,
  Question,
  TestDataType,
  courseDataProps,
} from '../types';
import {
  compareNumbers,
  computeChoosenAnswers,
  computeCorrectAnswerArr,
  computeCorrectAnswers,
  handleNextQuestion,
  handlePreviousQuestion,
  initializeCheckedState,
  updateCheckboxState,
  validateAndCheckAnswer,
} from '../utils/helpers';
type TestProviderProps = {
  children: ReactNode;
  data: any;
  survey: string;
};

type TestContextType = {
  courseData: courseDataProps;
  testData: TestDataType;
  finishTest: () => void;
  setAnswerArr: Dispatch<SetStateAction<AnswerItem[][]>>;
  answer: AnswerItem[];
  answerArr: AnswerItem[][];
  setAnswer: Dispatch<SetStateAction<AnswerItem[]>>;
  questions: Question[];
  handlePreviousQuestion: (
    currentQuestionIndex: number,
    setCurrentQuestionIndex: Dispatch<SetStateAction<number>>,
    answer: AnswerItem[],
    setAnswer: Dispatch<SetStateAction<AnswerItem[]>>,
    answerArr: AnswerItem[][],
    setAnswerArr: Dispatch<SetStateAction<AnswerItem[][]>>
  ) => void;
  handleNextQuestion: (
    currentQuestionIndex: number,
    setCurrentQuestionIndex: Dispatch<SetStateAction<number>>,
    test: Question[],
    answer: AnswerItem[],
    setAnswer: Dispatch<SetStateAction<AnswerItem[]>>,
    answerArr: AnswerItem[][],
    setAnswerArr: Dispatch<SetStateAction<AnswerItem[][]>>,
    setShowPage: Dispatch<SetStateAction<string>>
  ) => void;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: Dispatch<SetStateAction<number>>;
  handleCheckbox: (
    e: ChangeEvent<HTMLInputElement & { value: { id: number } }>,
    position: number
  ) => void;
  checkedState: CheckboxState;
  correctQuestions: number[];
  correctAnswersArr: CorrectAnswer;
  checkingResult: () => void;
  calculateDuration: () => void;
  duration: string;
  survey: string;
};
const TestContext = createContext({} as TestContextType);

export const useTestContext = () => {
  const context = useContext(TestContext);
  if (!context) {
    throw new Error('useTestContext must be used within a TestContextProvider');
  }
  return context;
};

export const TestProvider = ({ children, data, survey }: TestProviderProps) => {
  const courseData = data?.attributes;
  const testData = courseData?.test?.data?.attributes;
  const questions = testData?.questions || [];
  const [correctQuestions, setCorrectQuestions] = useState<number[]>([]);
  const [answerArr, setAnswerArr] = useState<AnswerItem[][]>([]);
  const [answer, setAnswer] = useState<AnswerItem[]>([]);
  const [checkedState, setCheckedState] = useState<CheckboxState>([]);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [duration, setDuration] = useState<string>('30m');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);

  useEffect(() => {
    setStartTime(new Date());
  }, []);

  useEffect(
    () => initializeCheckedState(testData, setCheckedState),
    [testData]
  );
  useEffect(() => {
    const updatedAnswerArr = [...answerArr];
    updatedAnswerArr[currentQuestionIndex] = answer;
    setAnswerArr(updatedAnswerArr);
  }, [answer]);

  const formatElapsedTime = (duration: number) => {
    const secondsTotal = Math.floor(duration / TIME_CONSTANTS.ONE_SECOND);
    const minutes = Math.floor(
      secondsTotal / TIME_CONSTANTS.ONE_MINUTE_IN_SECONDS
    );
    const seconds = secondsTotal % TIME_CONSTANTS.ONE_MINUTE_IN_SECONDS;

    if (minutes === 0) return `${seconds}s`;
    return `${minutes}m ${seconds}s`;
  };

  const calculateDuration = () => {
    if (startTime) {
      const endTime = new Date();
      const durationTime = endTime.getTime() - startTime.getTime();
      const formattedDuration = formatElapsedTime(durationTime);
      setDuration(formattedDuration);
    }
  };
  const correctAnswersArr = computeCorrectAnswers(questions);
  const choosenAnswers = computeChoosenAnswers(answerArr);
  const correctAnswerArr = computeCorrectAnswerArr(correctAnswersArr);

  const checkingResult = () => {
    const newCorrectQuestions = questions
      .map((question: Question, i: number) => {
        return validateAndCheckAnswer(
          choosenAnswers,
          correctAnswerArr,
          i,
          compareNumbers
        )
          ? question.id
          : null;
      })
      .filter((id: number) => id !== null);

    setCorrectQuestions(newCorrectQuestions);
  };

  const finishTest = () => {
    checkingResult();
    calculateDuration();
  };

  const handleCheckbox = (
    e: ChangeEvent<HTMLInputElement & { value: { id: number } }>,
    position: number
  ) =>
    updateCheckboxState(
      e,
      position,
      checkedState,
      currentQuestionIndex,
      setCheckedState,
      answer,
      setAnswer
    );

  return (
    <TestContext.Provider
      value={{
        courseData,
        testData,
        finishTest,
        setAnswerArr,
        answer,
        answerArr,
        setAnswer,
        questions,
        handlePreviousQuestion,
        handleNextQuestion,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        handleCheckbox,
        checkedState,
        correctQuestions,
        correctAnswersArr,
        checkingResult,
        calculateDuration,
        duration,
        survey,
      }}
    >
      {children}
    </TestContext.Provider>
  );
};
