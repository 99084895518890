exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-verified-tsx": () => import("./../../../src/pages/account-verified.tsx" /* webpackChunkName: "component---src-pages-account-verified-tsx" */),
  "component---src-pages-auth-facebook-callback-tsx": () => import("./../../../src/pages/auth/facebook/callback.tsx" /* webpackChunkName: "component---src-pages-auth-facebook-callback-tsx" */),
  "component---src-pages-auth-google-callback-tsx": () => import("./../../../src/pages/auth/google/callback.tsx" /* webpackChunkName: "component---src-pages-auth-google-callback-tsx" */),
  "component---src-pages-contact-styled-tsx": () => import("./../../../src/pages/contact.styled.tsx" /* webpackChunkName: "component---src-pages-contact-styled-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-course-tsx": () => import("./../../../src/pages/course.tsx" /* webpackChunkName: "component---src-pages-course-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-styled-ts": () => import("./../../../src/pages/index.styled.ts" /* webpackChunkName: "component---src-pages-index-styled-ts" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-password-tsx": () => import("./../../../src/pages/new-password.tsx" /* webpackChunkName: "component---src-pages-new-password-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-styled-tsx": () => import("./../../../src/pages/products.styled.tsx" /* webpackChunkName: "component---src-pages-products-styled-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-scorm-tsx": () => import("./../../../src/pages/scorm.tsx" /* webpackChunkName: "component---src-pages-scorm-tsx" */),
  "component---src-pages-shopping-cart-styled-tsx": () => import("./../../../src/pages/shopping-cart.styled.tsx" /* webpackChunkName: "component---src-pages-shopping-cart-styled-tsx" */),
  "component---src-pages-shopping-cart-tsx": () => import("./../../../src/pages/shopping-cart.tsx" /* webpackChunkName: "component---src-pages-shopping-cart-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-user-profile-edition-tsx": () => import("./../../../src/pages/user-profile-edition.tsx" /* webpackChunkName: "component---src-pages-user-profile-edition-tsx" */),
  "component---src-pages-user-profile-tsx": () => import("./../../../src/pages/user-profile.tsx" /* webpackChunkName: "component---src-pages-user-profile-tsx" */),
  "component---src-pages-verify-account-tsx": () => import("./../../../src/pages/verify-account.tsx" /* webpackChunkName: "component---src-pages-verify-account-tsx" */),
  "component---src-templates-chapter-page-tsx": () => import("./../../../src/templates/chapterPage.tsx" /* webpackChunkName: "component---src-templates-chapter-page-tsx" */),
  "component---src-templates-scorm-test-tsx": () => import("./../../../src/templates/scormTest.tsx" /* webpackChunkName: "component---src-templates-scorm-test-tsx" */),
  "component---src-templates-test-page-tsx": () => import("./../../../src/templates/testPage.tsx" /* webpackChunkName: "component---src-templates-test-page-tsx" */)
}

