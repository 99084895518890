import { useQuery } from '@apollo/client';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ME_QUERY } from '../graphql/queries/meQuery';

interface DarkModeContextValue {
  isDarkMode?: boolean;
  toggleDarkMode: (userDarkMode?: boolean) => void;
}
type DarkModeProviderProps = {
  children: ReactNode;
  value?: boolean;
};

const DarkModeContext = createContext({} as DarkModeContextValue);

export const useDarkModeContext = () => {
  const context = useContext(DarkModeContext);
  if (!context) {
    throw new Error(
      'useDarkModeContext must be used within a DarkModeProvider'
    );
  }
  return context;
};

export const DarkModeProvider = ({ children }: DarkModeProviderProps) => {
  const userProfile = useQuery(ME_QUERY);
  const darkMode = userProfile?.data?.me?.darkMode;
  const [isDarkMode, setDarkMode] = useState(darkMode);
  useEffect(() => {
    setDarkMode(darkMode);
  }, [darkMode]);

  const toggleDarkMode = (userDarkMode?: boolean) => {
    setDarkMode(userDarkMode);
  };

  const value: DarkModeContextValue = {
    isDarkMode,
    toggleDarkMode,
  };

  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
};
