export const validateAndCheckAnswer = (
  choosenAnswers: Array<number[]>,
  correctAnswerArr: Array<number[]>,
  index: number,
  compareNumbers: (a: number, b: number) => number
): boolean => {
  const choosen = choosenAnswers[index]?.sort(compareNumbers);
  const correct = correctAnswerArr[index]?.sort(compareNumbers);

  return JSON.stringify(choosen) === JSON.stringify(correct);
};

export const compareNumbers = (a: number, b: number) => a - b;
