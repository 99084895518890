import { ChoosenAnswer, CorrectAnswer, Question } from '../../../types';

export const computeCorrectAnswers = (questions: Question[]) => {
  return questions.map(question =>
    question.answers
      .filter(answer => answer.isCorrect)
      .map(correctAnswer => ({
        id: correctAnswer.id,
        isCorrect: correctAnswer.isCorrect,
      }))
  );
};

export const computeChoosenAnswers = (
  answerArr: CorrectAnswer
): ChoosenAnswer => {
  return answerArr.map(singleAnswer => singleAnswer.map(element => element.id));
};

export const computeCorrectAnswerArr = (
  correctAnswersArr: CorrectAnswer
): ChoosenAnswer => {
  return correctAnswersArr.map(singleCorrectAnswer =>
    singleCorrectAnswer.map(element => element.id)
  );
};
