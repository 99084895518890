import { Dispatch, SetStateAction } from 'react';
import { AnswerItem, Question } from '../../../types';

export const handleNextQuestion = (
  currentQuestionIndex: number,
  setCurrentQuestionIndex: Dispatch<SetStateAction<number>>,
  test: Question[],
  answer: AnswerItem[],
  setAnswer: Dispatch<SetStateAction<AnswerItem[]>>,
  answerArr: AnswerItem[][],
  setAnswerArr: Dispatch<SetStateAction<AnswerItem[][]>>,
  setShowPage: Dispatch<SetStateAction<string>>
) => {
  setAnswerArr(prevAnswers => {
    const updatedAnswerArr = [...prevAnswers];
    updatedAnswerArr[currentQuestionIndex] = answer;

    return updatedAnswerArr;
  });

  if (currentQuestionIndex < test.length - 1) {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  } else {
    setShowPage('TestResult');
    return;
  }

  setAnswer(answerArr[currentQuestionIndex + 1] ?? []);
};

export const handlePreviousQuestion = (
  currentQuestionIndex: number,
  setCurrentQuestionIndex: Dispatch<SetStateAction<number>>,
  answer: AnswerItem[],
  setAnswer: Dispatch<SetStateAction<AnswerItem[]>>,
  answerArr: AnswerItem[][],
  setAnswerArr: Dispatch<SetStateAction<AnswerItem[][]>>
) => {
  setAnswerArr(prevAnswers => {
    const updatedAnswerArr = [...prevAnswers];
    updatedAnswerArr[currentQuestionIndex] = answer;

    return updatedAnswerArr;
  });

  if (currentQuestionIndex > 0) {
    const newQuestionIndex = currentQuestionIndex - 1;
    setCurrentQuestionIndex(newQuestionIndex);
    setAnswer(answerArr[newQuestionIndex] ?? []);
  }
};
